@import "assets/css/constants.scss";

@mixin button-modifier($color, $fontColor, $borderColor, $borderRadius, $class) {
  &.button--#{$class} {
    background-color: $color;
    color: $fontColor;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    transition: filter 0.2s ease;

    &:hover:not(:disabled) {
      filter: brightness(90%);
    }
  }
}

@mixin button-invert-modifier(
  $color,
  $invertBgColor,
  $invertColor,
  $fontColor,
  $fontWeight,
  $borderColor,
  $borderRadius,
  $class
) {
  &.button--#{$class} {
    background-color: $color;
    color: $fontColor;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    font-weight: $fontWeight;
    transition: background-color 0.2s ease;

    &:hover:not(:disabled) {
      background-color: $invertBgColor;
      color: $invertColor;
    }
  }
}

.MuiButtonBase-root.simple-button {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  box-shadow: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: unset;
  text-transform: unset;

  &:hover:not(:disabled) {
    outline: 0;
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.4;
  }

  &.MuiButton-root.xxs {
    border-radius: 8px;
    min-height: 32px;
    max-height: 32px;
    padding: 2px 12px;
  }

  &.xs {
    min-height: 32px;
    max-height: 32px;
    padding: 2px 16px;
  }

  &.small {
    padding: 8px;
  }

  &.medium {
    padding: 12px 16px;
  }

  &.large {
    padding: 20px 32px;
  }

  &.r-sm {
    border-radius: 4px !important;
  }

  &.r-m {
    border-radius: 8px !important;
  }

  &.r-l {
    border-radius: 12px !important;
  }

  &:disabled.Mui-disabled {
    pointer-events: auto;
    cursor: not-allowed;
  }

  @include button-modifier($color-main, #fff, transparent, 6px, primary);
  @include button-modifier(#fff, $color-main, $color-main, 6px, light);
  @include button-modifier(#eaf1ff, $color-main, #eaf1ff, 6px, secondary);
  @include button-modifier($color-main, #eaf1ff, transparent, 64px, rounded);
  @include button-modifier($color-purple-main, #fff, transparent, 12px, purple);
  @include button-modifier($color-purple-main, #fff, transparent, 12px, rounded-purple);
  @include button-invert-modifier(
    #6e62e526,
    #6e62e5,
    #ffffff,
    $color-purple-main,
    500,
    $color-purple-main,
    12px,
    light-purple
  );

  @include button-invert-modifier(
    #6e62e526,
    #6e62e5,
    #ffffff,
    $color-purple-main,
    350,
    transparent,
    12px,
    borderless-light-purple
  );
}
