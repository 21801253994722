@import "assets/css/constants.scss";

.modal {
  width: 30%;

  .confirm-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 36px;

    .warning {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fdefda;
      color: #fab142;
      height: 10vh;
      width: 100%;
      padding: 10px;

      svg {
        font-size: 76px;
      }
    }

    .content-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 36px;

      .header {
        font-size: 18px;
        font-weight: 800;
      }

      .body {
        color: #212731;
        font-size: 14px;
        font-weight: 350;
      }
    }

    .action-buttons {
      display: flex;
      gap: 14px;

      :nth-of-type(1) {
        border: 0;
        text-transform: none;
        text-decoration: underline;
        color: $color-purple-main;
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }

      :nth-of-type(2) {
        background-color: $color-purple-main;
        border-radius: 12px;
        color: #ffffff;
        padding-left: 28px;
        padding-right: 28px;
      }
    }
  }
}
