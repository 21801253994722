.clocks {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  gap: 24px;

  div {
    margin-left: 20px;
    display: flex;
    align-items: center;
  }

  .state {
    white-space: pre-line;
  }
}
